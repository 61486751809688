import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import Lottie from "lottie-react";
import successAnimation from "../../../assets/lottie/gymLogin2.json";
import { doSignInWithEmailAndPassword, doSignInWithGoogle } from "../../../firebase/auth";
import { useAuth } from "../../../contexts/authContext";
import logo from "../../../assets/logo.jpg";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider, AuthErrorCodes } from "firebase/auth";

const errorMessages = {
    [AuthErrorCodes.INVALID_EMAIL]: 'L\'email fornita non è valida o la password inserita è errata.',
    [AuthErrorCodes.USER_DELETED]: 'Nessun utente trovato con questa email.',
    [AuthErrorCodes.INVALID_PASSWORD]: 'La password inserita è errata.',
    [AuthErrorCodes.NETWORK_REQUEST_FAILED]: 'Errore di rete. Per favore, riprova.',
    // Aggiungi altri codici di errore qui se necessario
  };



const Login = () => {
  const { userLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [isEmailRegistered, setIsEmailRegistered] = useState(true);

  const db = getFirestore();
  const auth = getAuth();

  const checkIfEmailRegistered = async (email) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking email registration:", error);
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      setShowAnimation(false);

      try {
        await doSignInWithEmailAndPassword(email, password);
        setShowAnimation(true);
        setTimeout(() => {
          setAnimationFinished(true);
        }, 3000);
      } catch (error) {
        console.error('Errore:', error);
        setErrorMessage(errorMessages[AuthErrorCodes.INVALID_EMAIL] || 'Errore sconosciuto. Per favore, riprova.');
        setIsSigningIn(false);
      }
    }
  };

  const onGoogleSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      setShowAnimation(false);

      const provider = new GoogleAuthProvider();

      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const email = user.email;

        const emailRegistered = await checkIfEmailRegistered(email);
        if (emailRegistered) {
          setShowAnimation(true);
          setTimeout(() => {
            setAnimationFinished(true);
          }, 3000);
        } else {
          setIsEmailRegistered(false);
          setIsSigningIn(false);
        }
      } catch (error) {
        console.error('Errore:', error);
        setErrorMessage(errorMessages[AuthErrorCodes.INVALID_EMAIL] || 'Errore sconosciuto. Per favore, riprova.');
        setIsSigningIn(false);
      }
    }
  };

  if (animationFinished) {
    return <Navigate to={"/home"} replace={true} />;
  }

  return (
    <div className="relative">
      <main className="w-full h-screen flex items-center justify-center">
        {showAnimation && (
          <div className="absolute inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-80">
            <Lottie
              animationData={successAnimation}
              loop={false}
              onComplete={() => setAnimationFinished(true)}
              className="w-64 h-64"
            />
          </div>
        )}
        <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border border-blue-500 rounded-xl">
          <div className="flex items-center justify-start space-x-4 mb-4">
            <div className="w-11 h-11 bg-blue-600 rounded-full flex items-center justify-center">
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full" />
            </div>
            <div>
              <h3 className="ml-16 text-gray-800 text-xl font-semibold sm:text-2xl">
                Benvenuto
              </h3>
            </div>
          </div>
          <form onSubmit={onSubmit} className="space-y-5">
            <div>
              <label className="text-sm text-gray-600 font-bold">Email</label>
              <input
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>
            <div>
              <label className="text-sm text-gray-600 font-bold">Password</label>
              <input
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
              />
            </div>
            {errorMessage && (
              <span className="text-red-600 font-bold">{errorMessage}</span>
            )}
            <button
              type="submit"
              disabled={isSigningIn}
              className={`w-full px-4 py-2 text-white font-medium rounded-lg ${
                isSigningIn
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-600 hover:shadow-xl transition duration-300"
              }`}
            >
              {isSigningIn ? "Sto accedendo..." : "Accedi"}
            </button>
          </form>
          <p className="text-center text-sm">
            Non hai un account?{" "}
            <Link to={"/register"} className="hover:underline font-bold">
              Registrati
            </Link>
          </p>
         
        </div>
      </main>
    </div>
  );
};

export default Login;
