import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2"; // Importa SweetAlert2
import { FaChart, FaPills } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCookieBite,
  faDrumstickBite,
  faWheatAwn,
  faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";


function formatTextWithKeywords(text) {
  const formattedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  const keywords = ["giorno off", "pre workout", "post workout"];

  let highlightedText = formattedText;
  keywords.forEach((keyword) => {
    const regex = new RegExp(`(${keyword})`, "gi");
    highlightedText = highlightedText.replace(regex, "<strong>$1</strong>");
  });

  return highlightedText;
}

// Imposta l'elemento radice per il modal
Modal.setAppElement("#root");

const NutritionModal = ({
  isOpen,
  onRequestClose,
  userId,
  onUpdateNutrition,
}) => {
  const [nutritionData, setNutritionData] = useState(null);
  const [currentWeek, setCurrentWeek] = useState("settimana1");
  const [integrationData, setIntegrationData] = useState({
    mattina: { grammi: "", tipo: "" },
    intraWorkout: { grammi: "", tipo: "" },
    postAllenamento: { grammi: "", tipo: "" },
    preNanna: { grammi: "", tipo: "" },
  });
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [giorniOnAlimentazione, setGiorniOnAlimentazione] = useState([]);
  const [giorniOffAlimentazione, setGiorniOffAlimentazione] = useState([]);
  const [pastiOn, setPastiOn] = useState([]);
  const [pastiOff, setPastiOff] = useState([]);

  const weeks = ["settimana1", "settimana3", "settimana5"];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    const fetchNutritionData = async () => {
      if (userId) {
        const dietaDocRef = doc(db, "dieta", userId);
        try {
          const dietaDocSnap = await getDoc(dietaDocRef);
          if (dietaDocSnap.exists()) {
            const data = dietaDocSnap.data();
            setNutritionData(data);
            setIntegrationData(data.integrazione || integrationData);
            setAdditionalNotes(data.noteAggiuntive || "");
            setGiorniOnAlimentazione(data.giorniOnAlimentazione || []);
            setGiorniOffAlimentazione(data.giorniOffAlimentazione || []);
            setPastiOn(data.pastiOn || []);
            setPastiOff(data.pastiOff || []);
          }
        } catch (error) {
          console.error("Errore nel recuperare i dati nutrizionali:", error);
        }
      }
    };

    fetchNutritionData();
  }, [userId, isOpen]);

  const renderAlimentazione = (pasti) => {
    return pasti.map((pasto, index) => (
      <div
        key={index}
        className="mt-4 p-2 border border-gray-400 rounded-lg shadow-md"
      >
        <h5
          className="text-xl font-semibold text-blue-600"
          style={{ fontSize: 22 }}
        >
          {`Pasto ${index + 1}`}
        </h5>
        <ul className="pl-2">
          {pasto.alimenti.map((alimento, alimentoIndex) => (
            <li
              key={alimentoIndex}
              className="mb-2 flex justify-between items-center"
            >
              <div>
                <p className="font-semibold">Alimento: {alimento.nome}</p>
                <p className="italic">Quantità: {alimento.quantita} grammi</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    ));
  };

  const handleNextWeek = () => {
    const currentIndex = weeks.indexOf(currentWeek);
    if (currentIndex < weeks.length - 1) {
      setCurrentWeek(weeks[currentIndex + 1]);
    } else {
      Swal.fire({
        title: "Settimana non disponibile",
        text: "Non ci sono dati per la settimana successiva.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePreviousWeek = () => {
    const currentIndex = weeks.indexOf(currentWeek);
    if (currentIndex > 0) {
      setCurrentWeek(weeks[currentIndex - 1]);
    } else {
      Swal.fire({
        title: "Settimana non disponibile",
        text: "Non ci sono dati per la settimana precedente.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 max-h-[70vh] flex flex-col mt-16">
        <h2 className="text-2xl font-bold mb-4 flex items-center justify-center sticky top-0 bg-white z-10">
          La tua alimentazione
        </h2>
        <div className="flex-1 overflow-auto">
          <div className="mt-2 text-xl">
            {nutritionData ? (
              <p className="text-s text-center mb-4 ">
                Visualizza tutti i dati relativi alla tua alimentazione e/o
                integrazione in base al pacchetto scelto.
              </p>
            ) : (
              <p></p>
            )}
            {nutritionData ? (
              <>
                <div className="overflow-x-auto">
                  <div className="font-bold text-2xl text-center mt-4 mb-8">
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      size="m"
                      className="mr-2 text-blue-500"
                    />
                    Valori nutrizionali
                  </div>
                </div>
                <div className="flex justify-between mb-4">
                  <button
                    onClick={handlePreviousWeek}
                    disabled={currentWeek === weeks[0]}
                    className="bg-blue-500 text-white py-1 px-2 rounded-lg hover:bg-blue-600"
                  >
                    Precedente
                  </button>
                  <span className="text-lg font-semibold">Settimane</span>{" "}
                  {/* Mostra la settimana corrente */}
                  <button
                    onClick={handleNextWeek}
                    disabled={currentWeek === weeks[weeks.length - 1]}
                    className="bg-blue-500 text-white py-1 px-2 rounded-lg hover:bg-blue-600"
                  >
                    Successiva
                  </button>
                </div>
                <table className="min-w-full border-collapse border border-gray-400 text-m">
                  <thead>
                    <tr>
                      <th className="border text-xl border-gray-400 px-2 py-1 text-center">
                        Giorni
                      </th>
                      <th
                        className="border text-lg border-gray-400 text-amber-500 py-1 text-center"
                        style={{ width: "90px" }}
                      >
                        Carbo{" "}
                        <FontAwesomeIcon
                          icon={faCookieBite}
                          size="xs"
                          className="ml-1"
                        />
                      </th>
                      <th
                        className="border text-lg border-gray-400 text-green-500 py-1 text-center"
                        style={{ width: "90px" }}
                      >
                        Prot.{" "}
                        <FontAwesomeIcon
                          icon={faDrumstickBite}
                          size="xs"
                          className="ml-1"
                        />
                      </th>
                      <th
                        className="border text-lg border-gray-400 text-red-500 py-1 text-center"
                        style={{ width: "90px" }}
                      >
                        Grassi{" "}
                        <FontAwesomeIcon
                          icon={faWheatAwn}
                          size="xs"
                          className="ml-1"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {nutritionData[currentWeek] ? ( // Controlla se i dati per la settimana corrente esistono
                      <>
                        <tr>
                          <td className="border text-xl border-gray-400 px-2 py-1 text-center">
                            Giorni ON (g)
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={
                                nutritionData[currentWeek].giorniOn.carbo || ""
                              } // Utilizza currentWeek
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={
                                nutritionData[currentWeek].giorniOn.proteine ||
                                ""
                              } // Utilizza currentWeek
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={
                                nutritionData[currentWeek].giorniOn.grassi || ""
                              } // Utilizza currentWeek
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-xl border-gray-400 px-2 py-1 text-center">
                            Giorni OFF (g)
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={
                                nutritionData[currentWeek].giorniOff.carbo || ""
                              } // Utilizza currentWeek
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={
                                nutritionData[currentWeek].giorniOff.proteine ||
                                ""
                              } // Utilizza currentWeek
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                          <td className="border border-gray-400 px-2 py-1 text-center">
                            <input
                              type="text"
                              value={
                                nutritionData[currentWeek].giorniOff.grassi ||
                                ""
                              } // Utilizza currentWeek
                              readOnly
                              className="w-full border rounded py-1 px-1 text-xl text-center"
                            />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          className="border text-xl border-gray-400 px-2 py-1 text-center"
                        >
                          Dati non disponibili per questa settimana.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {nutritionData[currentWeek] ? (
                  <>
                    <div className="mt-8 mb-4 flex items-center justify-center">
                      <span className="font-bold text-2xl">
                        Alimentazione giorni ON
                      </span>
                    </div>
                    {renderAlimentazione(
                      nutritionData[currentWeek]?.pastiOn || []
                    )}{" "}
                    {/* Mostra pasti ON */}
                    <div className="font-bold text-2xl mt-8 mb-4 flex items-center justify-center">
                      <span>Alimentazione giorni OFF</span>
                    </div>
                    {renderAlimentazione(
                      nutritionData[currentWeek]?.pastiOff || []
                    )}{" "}
                    {/* Mostra pasti OFF */}
                    <div className="mt-8 mb-4 flex items-center justify-center">
                      <span className="font-bold text-2xl">
                        Consigli generali{" "}
                      </span>
                    </div>
                    <div className="w-full p-2 border border-gray-300 rounded-lg shadow-md">
                      {/* Usa dangerouslySetInnerHTML per mostrare il testo formattato */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatTextWithKeywords(
                            nutritionData.noteAggiuntive || ""
                          ),
                        }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <p>{null}</p>
                )}
                <div className="flex justify-center mt-8">
                  <button
                    onClick={onRequestClose}
                    className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
                  >
                    Chiudi
                  </button>
                </div>
              </>
            ) : (
              <p className="text-center text-lg text-gray-500">
                Dieta non disponibile per questo utente.
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NutritionModal;
