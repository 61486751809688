import React from 'react'
import { useState } from "react";import { FaCircle } from "react-icons/fa";

const ToolTip = ({ dataScadenza, color }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className=" flex justify-center items-center ">
      <div
        className="relative flex flex-col items-center  "
        onMouseEnter={() => { setShowTooltip(true); }}
        onMouseLeave={() => { setShowTooltip(false); }}
      >
        <FaCircle color={color} />
        {showTooltip && (
          <div className="absolute bottom-5 left-1/2 -translate-x-1/2 
                        text-gray-800 text-xs rounded-md py-1 px-2 
                          whitespace-nowrap shadow-md
                          border border-gray-800 bg-white
                          ">
            { (color === '#CA3B3B' ? 'Scaduto il ' : color !== '#000000' ? 'Scade il ' : '') + dataScadenza} 
          </div>
        )}
      </div>
    </div>
  );
}; 

export default ToolTip;