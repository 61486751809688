import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doCreateUserWithEmailAndPassword } from "../../../firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase";

const RegisterStepTwo = ({ personalInfo, domandeInfo, onBack }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Le password non corrispondono");
      return;
    }

    if (!isRegistering) {
      setIsRegistering(true);
      try {
        await doCreateUserWithEmailAndPassword(email, password);
        const user = auth.currentUser;
        if (user) {
          await setDoc(doc(db, "users", user.uid), {
            email: user.email,
            ...personalInfo,
            ...domandeInfo,
          });
          navigate("/home");
        }
      } catch (error) {
        setErrorMessage(error.message);
        setIsRegistering(false);
      }
    }
  };

  return (
    <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border border-blue-600 rounded-xl">
      <div className="text-center mb-6">
        <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
          Completa la tua registrazione
        </h3>
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label className="text-sm text-gray-600 font-bold">Email</label>
          <input
            type="email"
            placeholder="Esempio@email.it"
            autoComplete="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
          />
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">Password</label>
          <input
            disabled={isRegistering}
            placeholder="Inserisci la tua password"
            type="password"
            autoComplete="new-password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
          />
        </div>
        <div>
          <label className="text-sm text-gray-600 font-bold">
            Conferma Password
          </label>
          <input
            disabled={isRegistering}
            type="password"
            placeholder="Inserisci la tua password"
            autoComplete="off"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600 shadow-sm rounded-lg transition duration-300"
          />
        </div>
        {errorMessage && (
          <span className="text-red-600 font-bold">{errorMessage}</span>
        )}

        {/* Link Privacy Policy */}
        <div className="text-sm text-center text-gray-500">
          Registrandoti accetti i nostri{" "}
          <button
            type="button"
            className="text-blue-600 hover:underline"
            onClick={() => setShowPrivacyModal(true)}
          >
            Termini e Privacy Policy
          </button>
          .
        </div>

        <div className="mt-20">
          <button
            type="submit"
            disabled={isRegistering}
            className={`w-full px-4 py-4 text-white font-medium rounded-lg ${
              isRegistering
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300"
            }`}
          >
            {isRegistering ? "Confermando..." : "Conferma"}
          </button>
          <button
            type="button"
            onClick={onBack}
            disabled={isRegistering}
            className={`w-full px-4 py-4 mt-2 text-white font-medium rounded-lg ${
              isRegistering
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300"
            }`}
          >
            Torna indietro
          </button>
        </div>
      </form>

      {/* Modal Privacy Policy */}
      {showPrivacyModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold text-gray-700">
              🔒 Privacy Policy
            </h2>
            <div className="mt-2 text-sm text-gray-600 space-y-2">
              <p>
                📅 <strong>Ultimo aggiornamento:</strong> 24-03-2025
              </p>
              <p>
                👋 Benvenuto su <strong>MD22TEAM</strong>! La tua
                privacy è importante per noi.
              </p>

              <h3 className="font-bold mt-4">📌 1. Quali dati raccogliamo?</h3>
              <p>- Email,  informazioni di registrazione,  dati tecnici.</p>

              <h3 className="font-bold mt-4">🤔 2. Perché raccogliamo questi dati?</h3>
              <p>🚀 Per creare e gestire il tuo account, migliorare l’esperienza utente e garantire sicurezza.</p>


              <h3 className="font-bold mt-4">🔒 3. Con chi condividiamo i tuoi dati?</h3>
              <p>🚫 I tuoi dati <strong>NON</strong> vengono venduti a terzi. Sono gestiti solo tramite Firebase.</p>


              <h3 className="font-bold mt-4">🌍 4. Dove sono conservati i tuoi dati?</h3>
              <p>📂 I dati sono salvati su Firebase (Google Cloud), con elevati standard di sicurezza.</p>


              <h3 className="font-bold mt-4">
                5. Per quanto tempo conserviamo i dati?
              </h3>
              <p>- Fino alla cancellazione del tuo account.</p>

              <h3 className="font-bold mt-4">
                6. Come puoi gestire o eliminare i tuoi dati?
              </h3>
              <p>
                - Puoi cancellare il tuo account scrivendo a <strong>mess2245@gmail.com</strong>.
              </p>

              <h3 className="font-bold mt-4">7. Sicurezza dei tuoi dati</h3>
              <p>- Usiamo Firebase e protocolli di sicurezza avanzati.</p>

              <h3 className="font-bold mt-4">
                8. Modifiche alla Privacy Policy
              </h3>
              <p>
                - Possiamo aggiornarla in futuro e ti informeremo in caso di
                cambiamenti.
              </p>
            </div>

            <div className="mt-4 flex justify-end">
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                onClick={() => setShowPrivacyModal(false)}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterStepTwo;
