import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';
import RegisterStepOne from './RegisterStepOne';
import RegisterStepTwo from './RegisterStepTwo';
import QuestionsForm from './QuestionsForm';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LottieAnimation from '../../LottieAnimation';

const Register = () => {
    const { userLoggedIn } = useAuth();
    const [step, setStep] = useState(1);
    const [personalInfo, setPersonalInfo] = useState({});
    const [domandeInfo, setDomandeInfo] = useState({});
    const [transitioning, setTransitioning] = useState(false); 
    const [showAnimation, setShowAnimation] = useState(false); 

    const handleNext = (info) => {
        if (step === 1) {
            setPersonalInfo(info);
        } else if (step === 2) {
            setDomandeInfo(info);
        }
        setTransitioning(true);
        setShowAnimation(true);

        setTimeout(() => {
            setStep(prev => prev + 1);
            setShowAnimation(false);
            setTransitioning(false);
        }, 3000); 
    };

    const handleBack = () => {
        setTransitioning(true);

        setTimeout(() => {
            setStep(prev => prev - 1);
            setTransitioning(false);
        }, 300); 
    };

    return (
        <>
            {userLoggedIn && (<Navigate to={'/home'} replace={true} />)}

            <main className="w-full h-screen flex justify-center items-center bg-gray-50">
                <div style={{ position: 'relative' }}>
                    {showAnimation && <LottieAnimation />}
                    <TransitionGroup>
                        <CSSTransition
                            key={step}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div>
                                {step === 1 && !transitioning && (
                                    <RegisterStepOne onNext={handleNext} />
                                )}
                                {step === 2 && !transitioning && (
                                    <QuestionsForm
                                        onNext={handleNext}
                                        onBack={handleBack}
                                    />
                                )}
                                {step === 3 && !transitioning && (
                                    <RegisterStepTwo
                                        personalInfo={personalInfo}
                                        domandeInfo={domandeInfo}
                                        onNext={handleNext}
                                        onBack={handleBack}
                                    />
                                )}
                                {step === 4 && (
                                    <Navigate to={'/login'} replace={true} />
                                )}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </main>
        </>
    );
};

export default Register;
