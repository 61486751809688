import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaPenNib, FaTrash } from "react-icons/fa";

const reorderExercises = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Resoconto = ({  
  numSettimane,
  dayExercises,
  setDayExercises,
  handleModify,
  handleDelete,
  handleSettimaneChange
}) => {
  const now = new Date();
  const dataScadenza = numSettimane ? 
    new Date(now.setDate(now.getDate() + numSettimane * 7)) : 
      undefined;
  const opzioni = { day: "2-digit", month: "short", year: "numeric" };
  const dataFormattata = dataScadenza
    ? new Intl.DateTimeFormat("it-IT", opzioni).format(dataScadenza).replace(/\//g, '-')
    : undefined;

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Prendiamo gli esercizi per il giorno di origine
    const sourceDay = source.droppableId;
    const exercisesForSourceDay = dayExercises[sourceDay] || [];

    // Riordiniamo gli esercizi per il giorno di origine
    const reorderedExercises = reorderExercises(
      exercisesForSourceDay,
      source.index,
      destination.index
    );

    let updatedDayExercises = { ...dayExercises };

    // Se il giorno di origine è lo stesso del giorno di destinazione
    if (sourceDay === destination.droppableId) {
      updatedDayExercises[sourceDay] = reorderedExercises;
    } else {
      // Spostiamo l'esercizio tra i due giorni
      const destinationDay = destination.droppableId;
      const exercisesForDestinationDay = dayExercises[destinationDay] || [];

      const movedExercise = exercisesForSourceDay[source.index];

      // Rimuoviamo l'esercizio dal giorno di origine
      updatedDayExercises[sourceDay] = reorderedExercises.filter(
        (exercise, index) => index !== source.index
      );

      // Aggiungiamo l'esercizio nel giorno di destinazione
      updatedDayExercises[destinationDay] = [
        ...exercisesForDestinationDay.slice(0, destination.index),
        movedExercise,
        ...exercisesForDestinationDay.slice(destination.index),
      ];
    }

    setDayExercises(updatedDayExercises);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="flex flex-row justify-between items-start mb-3">
        <h2 className="text-3xl font-bold">Resoconto per Giorno</h2>
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-end items-center ">
            <label htmlFor="num_settimane" className="mr-2 text-xl font-medium">Numero Settimane: </label>
            <input 
              type="number" 
              id="num_settimane"
              value={numSettimane}
              onChange={handleSettimaneChange}
              min={1}
              max={20}
              className="w-14 px-2 py-2 bg-transparent border border-gray-300 text-gray-500 text-sm rounded-lg
              focus:border-green-500 outline-none"
            />
          </div>
          <p className="mt-1 font-medium text-sm text-green-600"><span className="font-semibold">Data scadenza: </span>{dataFormattata}</p>
        </div>
      </div>
      {Object.keys(dayExercises).length > 0 ? (
        <div className="flex flex-wrap gap-4">
          {Object.keys(dayExercises).map((day) => (
            <div
              key={day}
              className="flex-1 min-w-[300px] border p-4 rounded-lg bg-gray-100"
            >
              <div className="flex items-center">
                <h3 className="text-xl font-bold mb-2">Giorno {day}</h3>
                <button
                  onClick={() => handleDelete(day)}
                  className="ml-2 mb-2 text-red-500 hover:text-red-700 focus:outline-none"
                >
                  <FaTrash size={20} />
                </button>
              </div>
              <Droppable droppableId={day}>
                {(provided) => (
                  <ul
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="list-none"
                  >
                    {dayExercises[day].length > 0 ? (
                      dayExercises[day].map((exercise, index) => {
                        const draggableId = `${exercise.name}-${day}-${index}`; // Aggiungiamo giorno e indice al draggableId
                        return (
                          <Draggable
                            key={draggableId}
                            draggableId={draggableId}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                className="mb-2 flex flex-col"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="font-medium">
                                  -{" "}
                                  <span className="text-xl">
                                    {exercise.name}
                                  </span>
                                  <button
                                    onClick={() => handleModify(day, index)}
                                    className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                                  >
                                    <FaPenNib size={20} />
                                  </button>
                                  <button
                                    onClick={() => handleDelete(day, index)}
                                    className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                                  >
                                    <FaTrash size={20} />
                                  </button>
                                </div>
                                {exercise.category === "cardio" ? (
                                  <div className="pl-4">
                                    Durata: {exercise.duration} minuti
                                  </div>
                                ) : (
                                  <div className="flex flex-col pl-4">
                                    <div>Serie: {exercise.series}</div>
                                    <div>Ripetizioni: {exercise.reps}</div>
                                    <div>Note: {exercise.note}</div>
                                  </div>
                                )}
                              </li>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <p>Nessun esercizio per questo giorno.</p>
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      ) : (
        <p>Nessun esercizio selezionato.</p>
      )}
    </DragDropContext>
  );
};

export default Resoconto;
