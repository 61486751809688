import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaDumbbell,
  FaApple,
  FaChartLine,
  FaIdCard,
} from "react-icons/fa";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Swal from "sweetalert2";
import MiddleModalNutrition from "../components/user/MiddleModalNutrition"; // importa il tuo nuovo componente
import { TablePagination } from "@mui/material";
import ToolTip from "./home/ToolTip";
const UserTable = ({ users, usersSchedaAllenamento, onUserClick }) => {
  const navigate = useNavigate();
  const now = new Date();
  const [sortOrderName, setSortOrderName] = useState("asc");
  const [isModalOpen, setIsModalOpen] = useState(false); // Stato per il modal
  const [selectedUserId, setSelectedUserId] = useState(null); // Stato per l'id selezionato

  // stati per la paginazione
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //cambio pagina
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // cambia righe per ogni pagina
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedUsers = useMemo(() => {
    const sorted = [...users].sort((a, b) => {
      if (sortOrderName === "asc") {
        return a.nome.localeCompare(b.nome);
      } else {
        return b.nome.localeCompare(a.nome);
      }
    });
    return sorted;
  }, [users, sortOrderName]);

  const sortByName = () =>
    setSortOrderName((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Sei sicuro di voler eliminare l'utente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Elimina",
      denyButtonText: "Annulla",
      confirmButtonColor: "red",
      denyButtonColor: "green",
    });

    if (result.isConfirmed) {
      try {
        const userDocRef = doc(db, "users", id);
        await deleteDoc(userDocRef);

        Swal.fire({
          title: "Eliminazione avvenuta con successo!",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "OK",
          confirmButtonColor: "green",
        }).then(() => {
          window.location.reload();
        });

        onUserClick(null);
      } catch (error) {
        Swal.fire("Errore durante l'eliminazione", "", "error");
      }
    } else if (result.isDenied) {
      Swal.fire("Operazione annullata", "", "info");
    }
  };

  const handleEditExercise = (id) => navigate(`/edit-exercise/${id}`);
  const handleViewProgression = (id) => navigate(`/view-progression/${id}`);
  const handleViewInformations = (id) => navigate(`/view-informations/${id}`);

  // Funzione per aprire il modal e impostare l'id selezionato
  const openModal = (id) => {
    setSelectedUserId(id);
    setIsModalOpen(true);
  };

  // Funzione per chiudere il modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-between mb-4">
        <button
          onClick={sortByName}
          className="text-blue-500 hover:text-blue-700 focus:outline-none"
        >
          Ordina per Nome {sortOrderName === "asc" ? "↓" : "↑"}
        </button>
      </div>

      <table className="min-w-full bg-white border border-gray-200">
        <thead className="bg-gray-100 border-b border-gray-200">
          <tr>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">
              ID
            </th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">
              Nome
            </th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">
              Cognome
            </th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">
              Data assegnazione scheda
            </th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">
              Scadenza
            </th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">
              Azioni
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, index) => {
              let color = "#000000";
              let scadenza;
              let dataScadenzaFormattata = "N/A";
              const fullName = user.nome + " " + user.cognome;
              const schedaAllenamento = usersSchedaAllenamento.find(
                (element) => element.id === fullName
              );

              if (schedaAllenamento) {
                const dataScadenza = schedaAllenamento.dataScadenza
                  ? schedaAllenamento.dataScadenza
                  : undefined;
                scadenza = dataScadenza ? new Date(dataScadenza) : null;
                if (dataScadenza) {
                  const splitData = dataScadenza.split("-");
                  const giorno = parseInt(splitData[0], 10);
                  const mese = parseInt(splitData[1], 10) - 1; // Sottrai 1 per l'indicizzazione dei mesi
                  const anno = parseInt(splitData[2], 10);
                
                  scadenza = new Date(anno, mese, giorno);
                
                  const diffTime = scadenza - now;
                  const diffDays = diffTime / (1000 * 3600 * 24);
                  color =
                    diffDays < 0
                      ? "#CA3B3B"
                      : diffDays <= 7
                      ? "#E6A800"
                      : "#4CAF50";
                
                  // Formatta la data di scadenza
                  const opzioniData = {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  };
                  dataScadenzaFormattata = new Intl.DateTimeFormat(
                    "it-IT",
                    opzioniData
                  ).format(scadenza);
                }
                
              }

              return (
                <tr
                  key={user.id}
                  className="border-b border-gray-200"
                  onClick={() => onUserClick(user)}
                >
                  <td className="py-3 px-4 text-sm text-gray-700">
                    {index + 1}
                  </td>
                  <td className="py-3 px-4 text-sm text-gray-700">
                    {user.nome.toUpperCase()}
                  </td>
                  <td className="py-3 px-4 text-sm text-gray-700">
                    {user.cognome.toUpperCase()}
                  </td>
                  <td className="py-3 px-4 text-sm text-gray-700">
                    {user.data_assegnazione ? user.data_assegnazione : "N/A"}
                  </td>
                  <td className="py-3 px-4 text-sm text-gray-700">
                    <div className="flex pl-5">
                    <ToolTip
                      dataScadenza={dataScadenzaFormattata}
                      color={color}
                    />
                    </div>
                    
                  </td>
                  <td className="py-3 px-4 text-center">
                    <div className="flex space-x-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditExercise(user.id);
                        }}
                        className="text-blue-500 hover:text-blue-700 focus:outline-none"
                        aria-label="Edit Exercise"
                      >
                        <FaDumbbell className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewInformations(user.id);
                        }}
                        className="text-orange-500 hover:text-orange-700 focus:outline-none"
                        aria-label="View Informations"
                      >
                        <FaIdCard className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(user.id); // Apri il modal quando clicchi sull'icona mela
                        }}
                        className="text-lime-500 hover:text-green-700 focus:outline-none"
                        aria-label="Edit Nutrition"
                      >
                        <FaApple className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl" />
                      </button>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewProgression(user.id);
                        }}
                        className="text-violet-500 hover:text-violet-700 focus:outline-none"
                        aria-label="View Progression"
                      >
                        <FaChartLine className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(user.id);
                        }}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                        aria-label="Delete User"
                      >
                        <FaTrash className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {/*componente per la paginazione della tabella */}
      <TablePagination
        component="div"
        count={sortedUsers != null ? sortedUsers.length : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />

      {/* Modal per la navigazione */}
      {isModalOpen && (
        <MiddleModalNutrition id={selectedUserId} closeModal={closeModal} />
      )}
    </div>
  );
};

export default UserTable;
