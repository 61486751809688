const Question = ({ index, label, name, type, options, answers, updateAnswers }) => {
    const selectedOption = answers[name] ? answers[name].option : "";
    const otherAnswer = answers[name] && (answers[name].option === 'other' || type === 'time') ? answers[name].value : "";
    const otherOption = type === 'radio' ? options.find(option => option.value === 'other') : undefined;

    const handleOptionChange = (e) => {
        const selected = e.target.value;
        updateAnswers(prev => ({ 
            ...prev, 
            [name]: { 
                option: selected, 
                value: selected === "other" ? otherAnswer : selected 
            } 
        }));
        
    }

    const handleOtherAnswerChange  = (e) => {
        updateAnswers(prev => ({ ...prev, [name]: { option: 'other', value: e.target.value } }));
    };

    return (
        <div>
            <label className="text-gray-800 text-lg font-medium">
                {`${index}. ${label}`}
            </label>
            <div className="flex flex-col justify-start items-start px-[10%] my-4 space-y-2">
                {type === 'radio' ? 
                    options.map((option, index) => {
                        const onClick = () => {
                            const selected = option.value;
                            updateAnswers(prev => ({ 
                                ...prev, 
                                [name]: { 
                                    option: selected, 
                                    value: selected === "other" ? otherAnswer : selected 
                                } 
                            }));
                        };

                        return (
                            <div
                                key={index}
                                onClick={onClick}
                                className={`w-full py-2 px-2 space-x-2 border rounded-md 
                                        ${selectedOption === option.value ? "border-blue-200 bg-blue-50" : null}`}
                            >
                                <input
                                    type="radio"
                                    id={option.value}
                                    name={name}
                                    value={option.value}
                                    checked={selectedOption === option.value}
                                    onChange={handleOptionChange}
                                />
                                <label
                                    htmlFor={option.value}
                                >
                                    {option.label}
                                </label>
                            </div>
                        );
                    }) : 
                    <input
                        type="time"
                        name={name}
                        value={otherAnswer}
                        onChange={handleOtherAnswerChange}
                        className="w-full h-[42px] px-2 py-2 text-gray-500 outline-none border focus:border-blue-600 rounded-lg bg-transparent"
                    />
                }
                <div className={`w-full transition-all duration-300 ease-in-out overflow-hidden ${selectedOption === 'other' ? "max-h-40" : "max-h-0"}`}>
                    { otherOption && otherOption.type === 'textarea' ? 
                        <textarea
                            value={otherAnswer}
                            onChange={handleOtherAnswerChange}
                            placeholder={otherOption.placeholder}
                            className="w-full px-2 py-2 outline-none text-gray-500 border focus:border-blue-600 rounded-lg bg-transparent"
                        /> : otherOption && otherOption.type === 'text' ?
                        <input
                            type="text"
                            value={otherAnswer}
                            onChange={handleOtherAnswerChange}
                            placeholder={otherOption.placeholder}
                            className="w-full px-2 py-2 outline-none text-gray-500 border focus:border-blue-600 rounded-lg bg-transparent"
                        /> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default Question;