import React, { useEffect, useState } from "react";
import Question from "./Question";
import Swal from "sweetalert2";
import questions from "../../../constants/registrationQuestions"

const QuestionsForm = ({ onNext, onBack }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const isDesktop = windowWidth >= 1024;
  const numVisibleQuestions = isDesktop ? 3 : 2;
  const totalQuestions = questions.length;
  const answered = Object.entries(answers).filter(([_, answer]) => answer.value !== '').length;
  const progress = (answered / questions.length) * 100
  const questionsToShow = questions.slice(currentQuestion, currentQuestion + numVisibleQuestions);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkAnswers = Object.entries(answers).every(([_, answer]) => answer.value);

    if(!checkAnswers) {
      Swal.fire({
        icon: "error",
        title: "Errore",
        text: "Rispondi a tutte le domande."
      });
      return;
    }

    const risposte = Object.fromEntries(Object.entries(answers).map(([question, answer]) => [question, answer.value]));
    onNext({ risposte });
  };

  const handleNext = () => {
    if(answered >= currentQuestion + numVisibleQuestions) {
      setTimeout(() => {
        setCurrentQuestion(prev => prev + numVisibleQuestions);
      }, 0);
    }
  };

  const handleBack = () => {
      setCurrentQuestion(prev => prev - numVisibleQuestions);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="w-96 sm:w-120 md:w-144 py-5 px-4 shadow-xl border rounded-xl bg-white border-blue-500">
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-6">
          <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
            Domande aggiuntive
          </h3>
          <p className="text-end text-xs mt-2 mb-1 mr-2">
            {answered} / {totalQuestions}
          </p>
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
            <div
              className={`h-2.5 rounded-full transition-all duration-300 ${progress === 100 ? "bg-green-500" : "bg-blue-500"}`}
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
        { questionsToShow.map((question, index) => (
            <Question
              key={index}
              index={currentQuestion + index + 1}
              label={question.label}
              name={question.name}
              type={question.type}
              options={question.options}
              answers={answers}
              updateAnswers={setAnswers}
            />
          ))
        }
        <div className="w-full flex flex-row justify-around items-center mt-">
          <button
            type="button"
            onClick={currentQuestion > 0 ? handleBack : onBack}
            className="w-[40%] px-4 py-2 text-blue-600 font-medium rounded-lg border-2 border-blue-600 bg-white hover:bg-gray-100 hover:shadow-lg"
          >
            {currentQuestion > 0 ? 'Precendente' : 'Indietro'}
          </button>

          { progress === 100 && currentQuestion + numVisibleQuestions >= totalQuestions ?  
              <button
                type="submit"
                className="w-[40%] px-4 py-2 text-white font-medium rounded-lg bg-blue-600 hover:bg-blue-700 hover:shadow-lg"
              >
                Avanti
              </button> :
              <button 
                type="button"
                onClick={handleNext}
                className="w-[40%] px-4 py-2 text-white font-medium rounded-lg bg-blue-600 disabled:opacity-50 hover:bg-blue-700 hover:shadow-lg
                  disabled:hover:bg-blue-600 disabled:hover:shadow-none"
                disabled={answered < currentQuestion + numVisibleQuestions}
              >
                Successiva
              </button>
          }
        </div>

      </form>
    </div>
  );
};

export default QuestionsForm;