const questions = [
    {
      name: 'infortunio',
      label: 'Hai mai subito un infortunio ?',
      type: 'radio',
      options: [
        { label: 'No', value: 'No' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi i tuoi infortuni", type: 'textarea' }
      ]
    },
    {
      name: 'dieta',
      label: 'Hai seguito una dieta in precedenza ?',
      type: 'radio',
      options: [
        { label: 'No', value: 'No' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi la tua precedente dieta", type: 'textarea' }
      ]
    },
    {
      name: 'problematicheAlimentari',
      label: 'Hai problematiche alimentari ?',
      type: 'radio',
      options: [
        { label: 'No', value: 'No' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi le tue problematiche alimentari", type: 'textarea' }
      ]
    },
    {
      name: 'sedentario',
      label: 'Come descriveresti il tuo stile di vita ?',
      type: 'radio',
      options: [
        { label: 'Sedentario', value: 'Sedentario' },
        { label: 'Attivo', value: 'Attivo' },
        { label: 'Molto attivo', value: 'Molto attivo' }
      ]
    },
    {
      name: 'obiettivoBreve',
      label: 'Qual è il tuo obiettivo a breve termine ?',
      type: 'radio',
      options: [
        { label: 'Dimagrire', value: 'Dimagrire' },
        { label: 'Mantenere', value: 'Mantenere' },
        { label: 'Aumentare', value: 'Aumentare' }
      ]
    },
    {
      name: 'obiettivoLungo',
      label: 'Qual è il tuo obiettivo a lungo termine ?',
      type: 'radio',
      options: [
        { label: 'Dimagrire', value: 'Dimagrire' },
        { label: 'Mantenere', value: 'Mantenere' },
        { label: 'Aumentare', value: 'Aumentare' }
      ]
    },
    {
      name: 'patologie',
      label: 'Hai particolari patologie ?',
      type: 'radio',
      options: [
        { label: 'No', value: 'No' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi le tue patologie", type: 'textarea' }
      ]
    },
    {
      name: 'sport',
      label: 'Hai mai fatto sport in precedenza ?',
      type: 'radio',
      options: [
        { label: 'No', value: 'No' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi gli sport che hai praticato", type: 'text' }
      ]
    },
    {
      name: 'integratori',
      label: 'Hai mai preso integratori ?',
      type: 'radio',
      options: [
        { label: 'No', value: 'No' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi gli integratori che assumi o che hai assunto in passato", type: 'textarea' }
      ]
    },
    {
      name: 'alimentiFastidio',
      label: 'Quali sono gli alimenti che ti creano fastidio ?',
      type: 'radio',
      options: [
        { label: 'Nessuno', value: 'Nessuno' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi quali sono gli alimenti che ti creano fastidio", type: 'textarea' }
      ]
    },
    {
      name: 'alimentiPreferiti',
      label: 'Quali sono i tuoi alimenti preferiti ?',
      type: 'radio',
      options: [
        { label: 'Non Specificare', value: 'Non specificato' },
        { label: 'Descrivi', value: 'other', placeholder: "Descrivi i tuoi alimenti preferiti", type: 'textarea' }
      ]
    },
    {
      name: 'acquaGiornaliera',
      label: 'Quanta acqua bevi giornalmente ?',
      type: 'radio',
      options: [
        { label: 'Meno di 1L', value: 'Meno di 1L' },
        { label: 'Tra 1L e 3L', value: 'Tra 1L e 3L' },
        { label: 'Più di 3L', value: 'Più di 3L' }
      ]
    },
    {
      name: 'orarioAllenamento',
      label: 'Quale orario di allenamento preferisci ?',
      type: 'time'
    }
  ];

export default questions;
