import React from 'react';

const WeeklyWorkoutsSelector = ({ value, onChange }) => {
    const values = [1, 2, 3, 4, 5];

    return (
        <div className="flex justify-center space-x-2 py-2">
            <div className="flex justify-between w-full max-w-[300px]">
                {values.map((val) => (
                    <button
                        key={val}
                        type='button'
                        onClick={() => onChange(val)}
                        className={`px-4 py-2 rounded-lg border transition duration-300
                            ${value === val ? 'bg-blue-600 text-white border-blue-600' : 'bg-white text-gray-600 border-gray-300'}
                            hover:bg-blue-300 hover:text-white`}
                    >
                        {val}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default WeeklyWorkoutsSelector;
